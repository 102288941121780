<template>
	<div>
		<span v-if="isLead">
			Delegation an Sie selbst oder andere Erhebungsverwalter nicht zulässig
 
		</span>	
		<div v-else>
			<v-row v-if="loading" class="pt-12">
				<v-col>
					<p class="text-center">
						<v-progress-circular indeterminate color="igaccent" size="70"></v-progress-circular>
					</p>
				</v-col>
			</v-row>
			<v-row v-else>
				<span v-if="!loggedIn">
					{{mwtr.PleaseSetUpAnAccountMessage}}
				</span>	
				<span v-if="success">
					<UserWelcome></UserWelcome>
				<!-- <p>{{mwtr.CollaborationActiated}}</p> -->				
					<!-- <v-btn color="primary"  to="/assessment">{{mwtr.GoToAssessment}}</v-btn> -->
				</span>			
				<span v-else>
					<div v-if="collaboration">
						<span v-if="collaboration.status == 'activated'">
							<p>{{mwtr.CollaborationActiated}}</p>
							<v-btn color="primary" @click="startAssessment">{{mwtr.GoToAssessment}}</v-btn>
						</span>
						<span v-if="collaboration.status == 'revoked'">
							{{mwtr.CollaborationRevoked}}
						</span>
					</div>
				</span>			
				<span v-if="error">
					{{error}}
				</span>	
				<span v-if="valid">
					<v-btn @click="go" color="primary">Zusammenarbeitsvorgang bestätigen</v-btn>
				</span>			
			</v-row>
		</div>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import firebase from "firebase"
	import Vuex from "vuex";
	import UserWelcome from "@/components/user/content/UserWelcome"
	export default {
		name: "Referral", 
		props: {
			code: {}
		},
		data: () => {
			return {
				loading: false, 
				success: false, 
				error: false

			}
		},
		components: {
			UserWelcome
		},
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
				loggedIn: state => state.auth.logged_in,
				user: state => state.user,
				collaboration(state){
					return state.collaborations[this.code]
				},
			}),
			isLead(){
				return this.$store.getters.isCurrentlyLeadRespondent
			},
			valid(){
				if( !this.collaboration ){
					return false;
				}
				if( this.isLead ){
					return false;
				}
				if( this.collaboration.status !== "published" ){
					return false
				}
				if( !this.user.id ){
					return false;
				}
				if( !this.loggedIn ){
					return false;
				}
				return true;

			}
		},
		watch: {
			loggedIn(){
				if (this.loggedIn ){
					if( this.code ){
						this.$store.dispatch("collaborations/fetchOne", this.code )
					}
				}
			},
			collaboration(){
				if( this.collaboration.user == this.user.id ){
					this.loading = false;
				}
			},
		},
		methods: {
			go(){
				if( !this.valid ){
					return false;
				}
				this.loading = true;
				this.activate().then(() => {
					this.success = true;
				}).catch( e => {
					this.error = e;
				})
			},
			startAssessment(){
				this.$store.dispatch("assessment/fetchOnlyMySections")
				this.$router.replace("/assessment")
			},
			async activate(){
				var functions = firebase.app().functions('europe-west1');
				var activateCollaboration = functions.httpsCallable('activateCollaboration');
				var collaboration = this.collaboration;
				collaboration.id = this.code;
				return await activateCollaboration({user: this.user.id, collaboration: collaboration });
			}
		},

	}
// </script>"