<template>
<div>
<p>Herzlich Willkommen bei InnGo - Ihrem Digitalisierungsassessment!</p>
<p>Ihre Umfrage wurde vom Geschäftsbereich IT aktiviert. </p>
<p>Nachfolgend werden Ihnen Digitalisierungziele aufgelistet. Bitte geben Sie entsprechend Ihre Bewertung an.</p>
<p>Sie können nun die Erhebung starten</p>
<v-btn to="/assessment" color="primary">Erhebung starten</v-btn>
</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "UserWelcome", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"